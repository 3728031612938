<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <b-nav-item v-for="menuItem in leftMenu"
                    :key="`menu_${menuItem.title}`"
                    :to="{name: menuItem.routeName, params: { reload: true }}"
                    @click="itemClickHandler(menuItem.clickHandler)">
          <i v-if="menuItem.iconClass" :class="menuItem.iconClass"></i>
          <template v-if="menuItem.title =='Analytics'">
            <chart-timeline-variant-shimmer-icon class="material-icon"></chart-timeline-variant-shimmer-icon>
          </template>
          <template v-if="menuItem.title =='Automations'">
            <file-document-icon class="material-icon">
            </file-document-icon>
          </template>
          <b-nav-text class="p-0 font-weight-600">{{ menuItem.title }}</b-nav-text>
        </b-nav-item>
      </template>

      <template slot="links-after">
        <b-nav class="navbar-nav mb-md-3">
          <b-nav-item :href="this.seatRole === seatRoles.HIRING_MANAGER ? faqHiringManagerLink : faqLink"
                      target="blank">
            <i class="ni ni-book-bookmark"></i>
            <b-nav-text class="p-0 font-weight-600">FAQ</b-nav-text>
          </b-nav-item>
          <b-nav-item id="support_link">
            <i class="ni ni-support-16"></i>
            <b-nav-text class="p-0 font-weight-600">Support</b-nav-text>
          </b-nav-item>
          <b-nav-item @click.prevent="LOGOUT_REQUEST">
            <i class="ni ni-button-power"></i>
            <b-nav-text class="p-0 font-weight-600">Log out</b-nav-text>
          </b-nav-item>
        </b-nav>
      </template>
    </side-bar>
    <div class="main-content">
      <div>
        <fade-transition :duration="200" mode="out-in" origin="center top">
          <!-- your content here -->
          <slot></slot>
        </fade-transition>
      </div>
    </div>
  </div>
</template>
<script>
/* eslint-disable no-new */
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import {createHmac} from 'crypto';
import {FadeTransition} from 'vue2-transitions';
import {mapActions, mapGetters, mapMutations} from 'vuex';
import {isDashboardRouteAllow} from '@/routes/guards';
import {CHANGE_PLANS_COMPONENT_SCREEN, LOGOUT_REQUEST} from '@/store/storeActions';
import {accountTypes, seatRoles, seatTypes, userTypes} from '@/store/userConstants';
import FileDocumentIcon from 'vue-material-design-icons/FileDocument.vue';
import ChartTimelineVariantShimmerIcon from 'vue-material-design-icons/ChartTimelineVariantShimmer.vue';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    FadeTransition,
    FileDocumentIcon,
    ChartTimelineVariantShimmerIcon,
  },
  data: function() {
    return {
      dashboardLeftMenu: [
        {
          title: 'Account',
          iconClass: 'ni ni-single-02',
          routeName: 'Account',
        },
        {
          title: 'Team',
          iconClass: 'ni ni-bullet-list-67',
          routeName: 'Team',
        },
        {
          title: 'Analytics',
          // iconClass: 'ni ni-ui-insights',
          routeName: 'Analytics',
        },
        {
          title: 'Templates',
          iconClass: 'ni ni-single-copy-04',
          routeName: 'Templates',
        },
        {
          title: 'Automations',
          // iconClass: 'ni ni-description',
          routeName: 'Automations',
        },
        {
          title: 'Calendars',
          iconClass: 'ni ni-calendar-grid-58',
          routeName: 'Calendars',
        },
        {
          title: 'Integrations',
          iconClass: 'ni ni-app',
          routeName: 'Integrations',
        },
        {
          title: 'Billing & Plans',
          iconClass: 'ni ni-credit-card',
          routeName: 'Billing',
          clickHandler: this.CHANGE_PLANS_COMPONENT_SCREEN,
        },
      ],
      faqLink: process.env.VUE_APP_SUPPORT_URL,
      faqHiringManagerLink: process.env.VUE_APP_HM_SUPPORT_URL,
      seatTypes,
      seatRoles,
    };
  },
  methods: {
    ...mapActions([
      LOGOUT_REQUEST,
    ]),
    ...mapMutations([
      CHANGE_PLANS_COMPONENT_SCREEN,
    ]),
    initScrollbar() {
      const isWindows = navigator.platform.startsWith('Win');
      if (isWindows) {
        initScrollbar('sidenav');
      }
    },
    itemClickHandler: function(handler) {
      !!handler && handler();
    },
    generateUserHash: function() {
      const hmac = createHmac('sha256', process.env.VUE_APP_INTERCOM_SECRET_KEY);
      hmac.update(this.userId.toString());
      return hmac.digest('hex');
    },
    bootIntercom() {
      this.$intercom.boot({
        user_id: this.userId,
        user_hash: this.generateUserHash(),
        email: this.userEmail,
        custom_launcher_selector: '#support_link',
      });
    },
    updateTeamTemplateRoute() {
      if (this.userType === userTypes.ADMIN &&
        this.accountType === accountTypes.TEAM &&
        !this.twilioNumberFormatted) {
        let templateIndex = this.dashboardLeftMenu.map(function(menu, index) {
          return menu.title === 'Templates' ? index : null;
        }).filter(function(data) {
          return data != undefined;
        });
        if (templateIndex.length) {
          templateIndex = templateIndex[0];
          this.dashboardLeftMenu[templateIndex].routeName = 'Team Templates';
        }
      }
    },
  },
  computed: {
    ...mapGetters([
      'userType',
      'accountType',
      'planType',
      'userId',
      'userFirstName',
      'userEmail',
      'twilioNumberFormatted',
      'seatType',
      'accountName',
      'seatRole',
    ]),
    leftMenu: function() {
      this.updateTeamTemplateRoute();
      return this.dashboardLeftMenu.filter(
        (menuItem) => isDashboardRouteAllow(menuItem.routeName, this.userType, this.accountType,
          this.planType, this.seatType, this.accountName, this.userEmail, this.seatRole));
    },
  },
  mounted() {
    this.initScrollbar();
    this.$intercom.shutdown();
    this.bootIntercom();
    // Added for hide menu for non-texter user
    if (this.seatType === this.seatTypes.NON_TEXTER) {
      const index = this.dashboardLeftMenu.findIndex((menu) => menu.routeName === 'Automations');
      if (index !== -1) {
        this.dashboardLeftMenu.splice(index, 1);
      }
    }
  },
  created() {

  },
};
</script>
<style lang="scss">
.material-icon {
  min-width: 2rem;
  line-height: 1rem;
  font-size: 0.9375rem;

  svg {
    height: 18px;
    width: 18px;
  }
}
</style>
