<template>
  <dashboard-layout>
    <b-container class="dashboard-page" fluid>
      <div class="my-4 d-flex justify-content-between align-items-center">
        <h1 v-if="!!title">{{ title }}</h1>
        <b-dropdown v-if="title === 'Automations' && isGreenHouseEnabled" ref="settingsDropdown" class="automation-menu"
                    no-caret right toggle-class="setting-btn" variant="outline-primary">
          <template #button-content>
            <b-icon aria-hidden="true" class="mr-1" icon="gear"></b-icon>
            Settings
          </template>
          <div class="dropdown-content pt-2">
            <div class="automation-title ml-3">Automations default setting to ‘On’ for new jobs created</div>
            <b-list-group>
              <!-- Customize list group items -->
              <b-list-group-item class="custom-list-item">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="option-title">Stage Changes</div>
                  <base-switch v-model="isStageEnabled" class="align-self-center"
                               offText="Off" onText="On" @input="handleStageAction"></base-switch>
                </div>
                <hr class="divider-line">
              </b-list-group-item>
              <b-list-group-item class="custom-list-item" style="padding-top:6px">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="option-title">Interviews</div>
                  <base-switch v-model="isInterviewEnabled" class="align-self-center"
                               offText="Off" onText="On" @input="handleInterviewAction"></base-switch>
                </div>
              </b-list-group-item>
            </b-list-group>
          </div>
        </b-dropdown>
        <b-modal ref="automationChangeModal" centered hide-footer hide-header no-close-on-backdrop>
          <div class="d-block text-center">
            <h3 class="automation-modal-title">
              Updating your default setting to ‘Off’ will impact all newly created jobs in the ATS.
            </h3>
            <p class="automation-modal-desc">This will not impact any settings for jobs that have already been created.
            </p>
          </div>
          <div class="d-flex justify-content-center mt-3">
            <b-button class="mr-2" pill style="flex: 1;" variant="outline-primary" @click="keepOn">
              Keep On
            </b-button>
            <b-button class="ml-2" pill style="flex: 1;" variant="primary" @click="confirmTurnOff">
              Confirm turn off
            </b-button>
          </div>
        </b-modal>
      </div>
      <slot></slot>
    </b-container>
  </dashboard-layout>
</template>

<script>
import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import {mapGetters} from 'vuex';

export default {
  components: {
    DashboardLayout,
  },
  props: {
    title: {
      type: String,
    },
  },
  data() {
    return {
      showPopover: false,
      isStageEnabled: true,
      isInterviewEnabled: true,
      modalType: null,
      isGreenHouseEnabled: false,
    };
  },
  computed: {
    ...mapGetters([
      'featuresEnabled',
    ]),
  },
  mounted() {
    if (this.featuresEnabled && this.featuresEnabled.includes('automation_v2')) {
      this.isGreenHouseEnabled = true;
    }
  },
  methods: {
    togglePopover: function() {
      this.showPopover = !this.showPopover;
    },
    handleStageAction: function() {
      this.isStageEnabled != this.isStageEnabled;
      if (!this.isStageEnabled) {
        this.modalType = 'stage';
        this.showPopover = false;
        this.$refs.automationChangeModal.show();
      }
    },
    handleInterviewAction: function() {
      this.isInterviewEnabled != this.isInterviewEnabled;
      if (!this.isInterviewEnabled) {
        this.modalType = 'interview';
        this.showPopover = false;
        this.$refs.automationChangeModal.show();
      }
    },
    keepOn: function() {
      if (this.modalType) {
        if (this.modalType == 'stage') {
          this.isStageEnabled = true;
        }
        if (this.modalType == 'interview') {
          this.isInterviewEnabled = true;
        }
      }
      this.$refs.automationChangeModal.hide();
      this.showPopover = false;
    },
    confirmTurnOff: function() {
      if (this.modalType) {
        if (this.modalType == 'stage') {
          this.isStageEnabled = false;
        }
        if (this.modalType == 'interview') {
          this.isInterviewEnabled = false;
        }
      }
      this.$refs.automationChangeModal.hide();
      this.showPopover = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-list-item {
  border: none;
  padding: 12px 16px;
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000;
}

.option-title {
  font-size: 15px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
  color: #000000;
}

.base-switch {
  margin-left: 10px;
}

.base-switch .custom-control-label::before,
.base-switch .custom-control-label::after {
  display: inline-block;
  vertical-align: middle;
}

.dropdown-content {
  width: 420px;
}

.automation-title {
  font-size: 13px;
  font-weight: 600;
  line-height: 17.7px;
  text-align: left;
}

.automation-modal-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  text-align: center;
}

.automation-modal-desc {
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
}

.divider-line {
  margin-top: 1rem;
  margin-bottom: 0rem;
}
</style>
